<template>
    <UiCard :loading="loading"
            :class="{ 'vut-product-card--unavailable': !product?.canBePurchased(variation) }"
    >
        <div class="relative flex h-full flex-col gap-3">
            <NuxtLink :to="productUrl" class="vut-product-card__link-area">
                <div class="vut-product-card__image">
                    <CoreImg
                        class="vut-product-card__image-el"
                        :src="product?.getHeroImage()"
                        :alt="product?.name"
                        :placeholder="[40, 25, 100, 4]"
                        sizes="100vw xs:100vw sm:50vw md:100vw lg:30vw xl:20vw xxl:20vw"
                        loading="lazy"
                    />
                </div>

                <span class="vut-product-card__title vut-h2 vut-link">
                    {{ product?.getName(variation) }}
                </span>
            </NuxtLink>

            <!--  CONTENT  -->
            <div class="vut-product-card__content">
                <ProductAvailability
                    :product="product"
                    :selected-variation="variation"
                />

                <ProductPrice
                    :product="product"
                    :selected-variation="variation"
                    align="center"
                />
            </div>

            <!--  ACTIONS (cart button)  -->
            <ProductAddToCartButton :product="product ?? null" :variation="variation" class="mx-auto mb-4" />
            <!--  end of ACTIONS  -->

            <div class="vut-product-card__info">
                <template v-if="product?.tags">
                    <template v-for="tag in product?.tags" :key="tag.id"> <!-- TODO remove span and v-if !== 'Nové' -->
                        <BaseUiBadge
                            v-if="tag.label !== 'Nové'"
                            content-class="font-bold uppercase"
                            :custom-background-color="`#${tag.background_color}`"
                            :custom-text-color="`#${tag.text_color}`"
                        >
                            {{ tag.label }}
                        </BaseUiBadge>
                    </template>

                    <BaseUiBadge
                        v-if="product.brand"
                        :custom-background-color="getFacultyColor(product.brand.name)"
                        content-class="font-bold uppercase"
                    >
                        {{ product.brand.name }}
                    </BaseUiBadge>
                </template>

                <ProductFavoriteButton
                    v-if="loggedIn"
                    :product="product"
                    :variation="variation"
                    class="pointer-events-auto"
                />
            </div>
        </div>
    </UiCard>
</template>

<script lang="ts" setup>
const {
    product,
    variation,
    loading,
} = defineProps<{
    product?: InstanceType<typeof ProductModel> | null | undefined
    variation?: InstanceType<typeof ProductVariationModel> | null
    loading?: boolean
}>()

const { loggedIn } = useAuth()
const { addToCart } = useCart()

const productUrl = computed<string>(() => getProductUrl(product, variation) ?? '')

async function addItemToCart() {
    if (!product?.id) return

    await addToCart({
        product_id: product.id,
        amount: 1,
        variation_id: variation?.id,
    })
}
</script>

<style lang="scss" scoped>
.vut-product-card__link-area {
    flex: 1;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    padding-bottom: 0.5rem;

    text-align: center;
}

.vut-product-card__image {
    width: 100%;

    aspect-ratio: 8 / 5;

    overflow: hidden;
}

.vut-product-card__image-el {
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: transform $vut-trans-time $vut-timing;
}

.vut-product-card--unavailable .vut-product-card__image-el {
    filter: grayscale(1);
}

.vut-product-card__link-area:hover .vut-product-card__image-el {
    transform: scale(1.05);
}

.vut-product-card__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    text-align: center;
}

.vut-product-card__title {
    padding: 0 0.5rem;

    text-decoration: underline;
}

.vut-product-card__link-area:hover .vut-product-card__title {
    text-decoration: none;
}

.vut-product-card__info {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;

    display: flex;
    align-items: start;
    justify-content: space-between;

    width: 100%;

    z-index: 1;
    pointer-events: none;
}


</style>
