export const PRODUCT_CARD_EMBEDS = [
    ProductModel.EMBED_COMPUTED_TAXED_PRICES,
    ProductModel.EMBED_DEFAULT_IMAGE_URL,
    ProductModel.EMBED_SECONDARY_IMAGE_URL,
    ProductModel.EMBED_URLS,
    ProductModel.EMBED_PRODUCT_AVAILABILITY,
    ProductModel.EMBED_BRAND,
    ProductModel.EMBED_TAGS,
    ProductModel.EMBED_CUSTOMER_PRODUCT_WISH_LIST_ITEM_ID,
    ProductModel.EMBED_CUSTOMER_PRODUCT_VARIATIONS_WISH_LIST_ITEMS,
] as const
